import { atom } from 'recoil';
import type { GetParams } from '@TS/campaigns/api';

/** NOTE: 캠페인 목록을 불러오는 parameter */
const campaignsParams = atom<GetParams>({
  key: 'campaignsParams',
  default: {
    page: 1,
    size: 20,
    orderby: 'campaign_id',
    status: 'all',
  },
});

export default campaignsParams;
