import { atom } from 'recoil';
import type { Campaign } from '@TS/campaigns/api';

interface campaignToChangeType {
  campaignName: string;
  campaignId: number;
  status: Campaign['status'];
  isTestCampaign: boolean;
}

/** NOTE: 캠페인 statusModal에서 쓰이는 상태를 관리함 */
const campaignToChange = atom<campaignToChangeType | null>({
  key: 'campaignToChange',
  default: null,
});

export default campaignToChange;
