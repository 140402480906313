import React from 'react';
import { useRecoilState } from 'recoil';
import { useQueryClient } from 'react-query';
import { Body, CloseIcon, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import campaignsParams from '@recoil/campaignsParams.atom';
import type { CampaignFilter } from '@TS/filter';
import { getMonthlyTotalByStatus } from '@function/campaign/getTotalCount';
import { FILTERS } from '@data/filterData';
import * as S from '../styles';
import { gtagEvent } from '@function/gtag';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface Props {
  closeModal: () => void;
}
const FilterModal = ({ closeModal }: Props): React.ReactElement => {
  const { t } = useTranslation('campaign/filter');
  const [params, setParams] = useRecoilState(campaignsParams);
  const { status } = params;

  const queryClient = useQueryClient();
  const monthlyStatus: Campaign.MonthlyStatus | undefined =
    queryClient.getQueryData('campaignsMonthlyStatus');

  const filterCampaignsByStatus = (option: CampaignFilter['eng']) => {
    const translator2Korean = i18next.getFixedT('ko', 'campaign/filter', '');

    setParams((prev) => ({
      ...prev,
      page: 1,
      status: option,
    }));

    gtagEvent({
      eventName: '필터링',
      parameter: { term: translator2Korean(option) },
    });

    closeModal();
  };

  return (
    <>
      <S.ModalTitle>
        <h3>{t('title')}</h3>
        <IconButton onClick={closeModal} color='ghost'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {FILTERS.map((option) => (
            <StyledButton
              key={option}
              onClick={() => filterCampaignsByStatus(option)}
              focused={option === status}
              fullWidth
            >
              <span>{t(option)}</span>
              <Body ml={1} tag='span'>
                ({getMonthlyTotalByStatus(option, monthlyStatus).toLocaleString()})
              </Body>
            </StyledButton>
          ))}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default FilterModal;
