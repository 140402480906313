import type { CampaignFilter } from '@TS/filter';
import { TO_MONTHLY_STATUS } from '@data/filterData';

export const getMonthlyTotalByStatus = (
  status: CampaignFilter['eng'],
  monthlyStatus: Campaign.MonthlyStatus | undefined
): number => {
  if (!monthlyStatus) return 0;
  if (status === 'all')
    return monthlyStatus.end + monthlyStatus.pending + monthlyStatus.active + monthlyStatus.test;
  if (status === 'proceeding') return monthlyStatus.active + monthlyStatus.test;
  return monthlyStatus[TO_MONTHLY_STATUS[status]];
};
