export const FILTERS: Campaign.Scheme.Status.NamedStatus[] = [
  'all',
  'proceeding',
  'inactive',
  'complete',
];

export const TO_MONTHLY_STATUS: Record<
  Extract<Campaign.Scheme.Status.NamedStatus, 'inactive' | 'complete'>,
  keyof Campaign.MonthlyStatus
> = {
  inactive: 'pending',
  complete: 'end',
};
