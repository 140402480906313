import type { GetParams } from '@TS/campaigns/api';

const CAMPAIGN_STATUS: Campaign.Scheme.Status.Parameter[] = [
  'complete',
  'test',
  'active',
  'inactive',
];
const stringifyStatus = (string: string): string =>
  CAMPAIGN_STATUS.filter((item) => item === string).join(',');

export const defineStatus = (status: GetParams['status']): string => {
  if (status === 'all') return CAMPAIGN_STATUS.join(',');
  if (status === 'proceeding')
    return CAMPAIGN_STATUS.filter((item) => item === 'test' || item === 'active').join(',');

  return stringifyStatus(status);
};
